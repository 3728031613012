<template>
  <Layout>
    <div>
      <div class="container-fluid pt-5">
        <div class="row">
          <div class="offset-md-3 col-md-6">
            <div class="confirmation-container text-center">
              <div class="logo image"></div>
              <div class="for-applying">for applying.</div>
              <div class="confirmation-guide mt-2">
                A verification mail has been sent to your registered
                email account. Please check your inbox to verify.
              </div>
              <div class="mt-3 d-grid gap-2">
                <button class="submit">
                  <RouterLink to="/">
                    Start Application
                  </RouterLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 150px"></div>
  </Layout>
</template>
<script>
import Layout from "@/layouts/main";

export default {
  name: "confirmation",
  components: {
    Layout
  }

}
</script>

<style scoped>

</style>